import { useNotificationStore } from "~/pinia/notifications"

export default defineNuxtRouteMiddleware(async () => {
  const { api } = useApi()
  const authStore = useAuthStore()
  const notificationStore = useNotificationStore()

  try {
    const response = await api<any>("/cs/api/session")
    if (response.status === 200 && response.data?.user) {
      authStore.setUser(response.data.user)
    }
  } catch (error) {
    console.error(error)
    authStore.resetState()

    notificationStore.add({
      type: "error",
      message:
        "An error occurred while checking your session. Please try again.",
    })

    return navigateTo("/login/")
  }
})
